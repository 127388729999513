import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from './core/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements AfterViewInit {

    private container: HTMLDivElement | null = null;

    /**
     * Constructor
     */
    constructor(private authService: AuthService,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }


    ngAfterViewInit() {
        this.authService.loggedIn.subscribe(
            (loggedInStatus ) => {
                if (loggedInStatus && environment.production) {
                    this.loadChatScript();
                }
            }
        );
    }


    private loadChatScript() {
        if (this.container) return; // 

        this.container = this.renderer.createElement('div');
        this.container.id = 'chat-container';
        this.renderer.appendChild(this.el.nativeElement, this.container);

        const BASE_URL = "https://app.intertelconversa.com";
        const script = this.renderer.createElement('script') as HTMLScriptElement;
        script.src = `${BASE_URL}/packs/js/sdk.js`;
        script.defer = true;
        script.async = true;

        this.renderer.appendChild(this.container, script);

        script.onload = () => {
            (window as any).conversaSDK?.run({
                websiteToken: 'BK5cM8EToT1g96LtUgDErNgs', 
                baseUrl: BASE_URL,
            });
        };
    }


}
