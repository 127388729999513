<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>

    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Usuario loggeado</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
   <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Configuración</span>
    </button> -->
    <button  *ngxPermissionsExcept="['ROLE_USER']" mat-menu-item (click)="openDialog()">
        <mat-icon>edit</mat-icon>
        <span>Firma Electrónica</span>
    </button>
    <mat-divider *ngxPermissionsExcept="['ROLE_USER']" class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>

<!-- Diálogo -->
<ng-template #dialogTemplate>
    <h2 mat-dialog-title>Cargar firma</h2>
    <mat-dialog-content class="dialog-content">
        <div class="file-upload-section">
            <label for="p12File">Selecciona tu archivo .P12</label>
            <input id="p12File" type="file" (change)="fileTo64($event)" accept="application/x-pkcs12" class="file-input"/>
        </div>

        <div class="file-upload-section">
            <label for="certFile">Selecciona tu certificado</label>
            <input id="certFile" type="file" (change)="fileTo64($event)" accept="application/pkix-cert, application/x-x509-ca-cert" class="file-input"/>
        </div>



        <div class="password-section">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Contraseña P12</mat-label>
                <input matInput [(ngModel)]="passwordP12" type="password" />
            </mat-form-field>
            <mat-hint>Ingresa la contraseña P12.</mat-hint>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="dialog-actions">
        <button mat-button (click)="closeDialog()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="saveSign()" [disabled]="loadingButton">Guardar Firma</button>
        <mat-spinner *ngIf="loadingButton" diameter="20"></mat-spinner>
    </mat-dialog-actions>
</ng-template>
