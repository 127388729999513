import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { BehaviorSubject, catchError, concatMap, Observable, of, switchMap, throwError } from 'rxjs';
import { UserManagement } from '../../entities/user-management/model/user-management.model';
import { User } from '../user/user.types';
import { environment } from '../../../../src/environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { DataService } from 'app/shared/data.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private apiUrl = environment.apiUrl;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _ngxPermissionsService = inject(NgxPermissionsService);
    private _dataService = inject(DataService);
    public loggedIn = new BehaviorSubject<boolean>(false);
    /**
    * Constructor
    */
    constructor(
    ) {
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        //return this._httpClient.post('api/auth/forgot-password', email);
        return this._httpClient.post(this.apiUrl + '/api/account/reset-password/init', email);
    }

    accountActivate(_key: string): Observable<any> {
        const params = new HttpParams().set('key', _key);
        return this._httpClient.get(this.apiUrl + '/api/activate', { params });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(_key: string, _newPassword: string): Observable<any> {
        // return this._httpClient.post('api/auth/reset-password', password);
        var values = { key: _key, newPassword: _newPassword }
        return this._httpClient.post(this.apiUrl + '/api/account/reset-password/finish', values);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            throw new Error('User is already logged in.');
        }
        console.log(this.apiUrl + '/api/authenticate');
        return this._httpClient.post(this.apiUrl + '/api/authenticate', credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.id_token;

                // Set the authenticated flag to true
                this._authenticated = true;
                this.loggedIn.next(true);

                // Store the user on the user service
                //this._userService.user = response.user;

                // Return a new observable with the response
                return this._httpClient.get(this.apiUrl + '/api/account',
                    {
                        headers: {
                            Authorization: `Bearer ${this.accessToken}`,
                        },
                    }
                );
            }),switchMap((user: UserManagement) => {
                if (user !== undefined) {

                    // Store the user on the user service
                    this._userService.user = this.convertManagementUserToUser(user);
                    //this._userService.user = userMockData;
                    // Return a new observable with the user data
                    localStorage.setItem('auth', JSON.stringify(user));
                    return of(user).pipe(
                        concatMap(response => {
                            this.addAuthorities(user.authorities);
                            return of(user);
                        })
                    );
                }
                else {
                    localStorage.removeItem('accessToken');
                    this.removeAuthorities();
                    // Set the authenticated flag to false
                    this._authenticated = false;
                    this.loggedIn.next(false);
                    // Return the observable
                    return of(true);
                }
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.get(this.apiUrl + '/api/account', {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: UserManagement) => {

                if (!response) return of(false);

                this._authenticated = true;
                this.loggedIn.next(true);

                // Store the user on the user service
                this._userService.user = this.convertManagementUserToUser(response);

                // Return true
                return of(true).pipe(
                    concatMap(x => {
                        this.addAuthorities(response.authorities);
                        return of(true);
                    })
                );

            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;
        this.loggedIn.next(false);
        this.removeAuthorities();
        this._dataService.clearLocalStorage();
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { email: string; firstName: string; lastName: string; password: string }): Observable<any> {

        const usuario: {
            email: string;
            firstName: string;
            lastName: string;
            password: string;
            login: string } = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            password: user.password,
            login: user.email
          };
        return this._httpClient.post(this.apiUrl + '/api/register', usuario);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    convertManagementUserToUser(response: UserManagement) {
        const _user: User = {
            id: response.id.toString(),
            name: response.firstName,
            email: response.email,
            avatar: "assets/images/avatars/people.png",
            status: "online",
            login: response.login,
        };

        return _user;
    }

    addAuthorities(authorities: string[]) {
        this._ngxPermissionsService.loadPermissions(authorities);
    }
    removeAuthorities() {
        this._ngxPermissionsService.flushPermissions();
    }
    ciuddanoRegister(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post(this.apiUrl + '/api/admin/users/register-ciudadano', user);
    }
}
