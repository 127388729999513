import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Subject, takeUntil } from 'rxjs';
import {MatDialog, MatDialogActions, MatDialogContent} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormGroup, FormsModule, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import { NgxPermissionsModule } from 'ngx-permissions';


@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user',
    standalone     : true,
    styleUrl: './user.component.scss',
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule,
              MatDialogContent, MatProgressSpinnerModule, MatInputModule, MatDialogActions, NgxPermissionsModule, FormsModule ],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    uploadedFiles: any[] = [];
    passwordP12: any;
    loadingButton: boolean = false;
    ofFirmantes: FormGroup;
    @Input() showAvatar: boolean = true;
    @ViewChild('dialogTemplate') dialogTemplate: any;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private snackBar: MatSnackBar
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.formularioOfFirmantes();
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) =>
            {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status,
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }

    openDialog(): void {
        this.dialog.open(this.dialogTemplate);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    fileTo64(event: any) {
        console.log(event);
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploadedFiles.push(file);

            // Opcional: Lee el archivo como Base64
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const base64String = e.target.result;
                console.log(`Archivo ${file.name} en Base64:`, base64String);
                // Puedes guardar `base64String` en otra variable o procesarlo como necesites
            };
            reader.readAsDataURL(file);
        }
    }

    async saveSign(): Promise<void> {
        const base64Strings = [];
        this.loadingButton = true;

        // Crear un array de promesas para convertir cada archivo a base64
        const promises = this.uploadedFiles.map(file => this.fileToBase64(file)
            .then((base64String: string) => {
                console.log(base64String);

                if (file.type === 'application/x-pkcs12') {
                    this.ofFirmantes.get('archivoP12Base64').setValue(base64String.split(',')[1]);
                    this.ofFirmantes.get('nombreArchivoP12').setValue(file.name);
                } else if (file.type === 'application/pkix-cert' || file.type === 'application/x-x509-ca-cert') {
                    this.ofFirmantes.get('archivoCertificadoBase64').setValue(base64String.split(',')[1]);
                    this.ofFirmantes.get('nombreCertificado').setValue(file.name);
                }

                base64Strings.push(base64String);
            })
            .catch(error => {
                console.error('Error al convertir archivo a Base64:', error);
            })
        );

        // Esperar a que todas las promesas se resuelvan
        await Promise.all(promises);

        // Asignar valores adicionales antes de enviar
        this.ofFirmantes.get('passwordSign').setValue(this.passwordP12);
        this.ofFirmantes.get('idUser').setValue(this.user.id);


        // Llamada al servicio para actualizar firmante
        this._userService.updateFirmante(this.ofFirmantes.value).subscribe(
            response => {
                this.snackBar.open('Se ha guardado las firmas.', 'Cerrar', {
                    duration: 5000,
                    panelClass: ['snack-bar-exito']
                });
                this.resetForm();
            },
            err => {
                console.error('Error al guardar la firma:', err);
                this.snackBar.open('Hubo un error al guardar la firma, por favor contacta con el administrador.', 'Cerrar', {
                    duration: 5000,
                });
                this.resetForm();
            }
        );
    }

// Función auxiliar para convertir archivo a Base64
    fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

// Función auxiliar para resetear el formulario y el estado
    resetForm(): void {
        this.uploadedFiles = [];
        this.passwordP12 = '';
        this.loadingButton = false;
    }

    formularioOfFirmantes() {
        this.ofFirmantes = this.formBuilder.group({
            nombreArchivoP12: ['', Validators.required],
            archivoP12Base64: ['', [Validators.required, Validators.min(10)]],
            passwordSign: ['', Validators.required],
            nombreCertificado: ['', Validators.required],
            archivoCertificadoBase64: ['', Validators.required],
            idUser: ['', Validators.required],
        });
    }

}
